import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import imgCover from "../../assets/images/hope-probe/launch-vehicle/cover.png"
import imgRocket from "../../assets/images/hope-probe/launch-vehicle/rocket.png"

const LaunchVehiclePage = () => (
  <Layout lang="ar">
    <SEO title="Launch Vehicle" lang="ar" />
    <main>
      <div className="uk-cover-container" data-uk-height-viewport="offset-top: true; offset-bottom: 40">
        {/* <canvas width="100%" height="100%"></canvas> */}
        <img src={imgCover} alt="" style={{transform: "translate(50%,-50%)"}} data-uk-cover />
        <div className="uk-overlay uk-position-cover header-overlay"></div>
      </div>
      <section id="about-emm" style={{marginTop: "-100px", position: "relative"}}>
          <div className="large-container pb6">
            <div className="standard-container standard-bg">
                <div className="mb5">
                  <h2 className="relative">
                  صاروخ الإطلاق
                    <div className="text-underline-heavy"></div>
                  </h2>
                  <p className="mt5">
                  سينطلق "مسبار الأمل" إلى المريخ من مركز تانيغاشيما الفضائي في اليابان على متن صاروخ (إتش 2 إيه – 202) وهو جزء من عائلة صواريخ (إتش 2 إيه) اليابانية عالية الأداء والتي يتم تطويرها بواسطة شركة ميتسوبيشي للصناعات الثقيلة.
                  </p>
                  <p>
                  وتتولى شركة ميتسوبيشي للصناعات الثقيلة جميع الخدمات المتعلقة بإطلاق المركبات الفضائية بما في ذلك تصنيع صواريخ الإطلاق وتصميم البرامج التقنية اللازمة لعملية الإطلاق وخدمات الدعم اللوجيستي لعملية الإطلاق في مركز تانيغاشيما الفضائي. ويتكون صاروخ الإطلاق من مرحلتين إضافة إلى سطح انسيابي وزوج من معزز الصواريخ، ويستخدم نظام دفع الصاروخ الهيدروجين السائل والأكسجين السائل. في حين تتولى وكالة الفضاء اليابانية (جاكسا) عمليات توجيه الصاروخ بدقة وسلامة وتأمين وصوله للمسافة الآمنة إضافة إلى إدارة منشأة موقع الإطلاق.
                  </p>
                </div>
                <div className="mv5">
                    <h3>
                    المرحلة الأولى
                    </h3>
                    <p>
                    تتكون المرحلة الأولى من نظام الصواريخ -إتش 2 إيه- من محرك (إل إي -7 إيه) عالي الأداء، وخزان لنظام الدفع مليء بالهيدروجين السائل والأكسجين السائل، وقسم مركزي لربط الخزانات ببعضها، وقسم داخلي لربط المرحلة الأولى بالثانية.
                    </p>
                </div>
                <div className="mv5">
                    <h3>
                    معزز الصواريخ الصلب
                    </h3>
                    <p>
                    تصل معززات الصواريخ الصلبة إلى كامل قدرتها على الدفع من خلال إطلاق النيران لمدة 100 ثانية قبل عملية الإطلاق، وهناك معززان من هذا النوع تتضمنهم المرحلة الأولى من الصاروخ.
                    </p>
                </div>
                <div className="mv5">
                    <h3>
                    المرحلة الثانية
                    </h3>
                    <p>
                    تتكون المرحلة الثانية للصاروخ من محرك (إل إي – 5 بي)، وخزان لنظام الدفع مليء بالهيدروجين السائل والأكسجين السائل والأجهزة الإلكترونية اللازمة لتوجيه الصاروخ ويمكن تشغيل هذا المحرك حتى 3 مرات.
                    </p>
                </div>
                <div className="flex flex-column flex-row-ns mb5">
                    <div className="w-100 w-40-ns">
                        <div className="mb4">
                        <div className="animated-image-container">
                            <img src={imgRocket} alt=""/>
                        </div>
                        </div>
                    </div>  
                    <div className="w-100 w-60-ns pr5-ns z-index-10">
                        <div>
                            <p className="mv4">
                            كما تتميز المرحلة الثانية أيضاً بنظام التحكم في تفاعل الغاز النفاث (الهيدرازين)، والذي يستخدم للتحكم في الصاروخ واستقرار عملية الدفع بالمرحلة الثانية قبل وبعد فصل المركبة الفضائية.
                            </p>
                        </div>
                        <div className="negative-margin-left-150 w-80-ns">
                        <table className="uk-table uk-table-responsive uk-table-small home-custom-table">
                            <tbody className="text-white">
                                <tr>
                                    <td className="text-highlight-brown">
                                    النوع
                                    </td>
                                    <td>
                                    إتش 2 إيه – 202
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-highlight-brown">
                                    الارتفاع
                                    </td>
                                    <td>
                                    53 متراً
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-highlight-brown">
                                    الكتلة الإجمالية
                                    </td>
                                    <td>
                                    289 طن متري (لا تشمل كتلة المسبار)
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-highlight-brown">
                                    محرك المرحلة الأولى
                                    </td>
                                    <td>
                                    إل إي -7 إيه
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-highlight-brown">
                                    محرك المرحلة الثانية
                                    </td>
                                    <td>
                                    إل إي – 5 بي
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-highlight-brown">
                                    معزز الصاروخ
                                    </td>
                                    <td>
                                    2
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-highlight-brown">
                                    الطاقة الاستيعابية للإطلاق (ملحوظة)
                                    </td>
                                    <td>
                                    4.0 طن متري
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        </div>
                    </div>
                </div>
            </div>
          </div>  
      </section>
    </main>
  </Layout>
)

export default LaunchVehiclePage
